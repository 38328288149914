export const state = () => ({
  dfmStock: {},
  dfmgi: {},
})

//Mutations - Populating the state with data
export const mutations = {
  SET_DFMSTOCK(state, payload) {
    state.dfmStock = payload
  },
  SET_DFMGI(state, payload) {
    state.dfmgi = payload
  },
}
export const getters = {
  getDfmStock: (state) => {
    return state.dfmStock
    // return arg;
  },
  getdfmgi: (state) => {
    return state.dfmgi
    // return arg;
  },
}
export const actions = {
  async getDfmStock({ commit }) {
    const stockRes = await fetch(`${this.$config.apiBaseUrl}/data`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `Command=companyprofile&lang=en&symbol=DFM`,
    })
    let data = await stockRes.json()
    commit('SET_DFMSTOCK', data)
  },
  async getdfmgi({ commit }) {
    const dfmgiRes = await fetch(`${this.$config.apiMwUrl}/status`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    let data = await dfmgiRes.json()
    commit('SET_DFMGI', data)
  },
}
