export default function ({ req, redirect }) {
  const subDomain1 = 'ipos.dfm.ae'
  const subDomain2 = 'ipo.dfm.ae'
  const destination = 'https://www.dfm.ae/investing/services/ipo-subscriptions'
  const home = '/'
  // console.log(req.headers)

  if (req && req.headers && req.headers.host === subDomain1) {
    return redirect(destination)
  }
  if (req && req.headers && req.headers.host === subDomain2) {
    return redirect(destination)
  }
}
