export const state = () => ({
  allCirculars: [],
})

//Mutations - Populating the state with data
export const mutations = {
  SET_CIRCULARS(state, payload) {
    state.allCirculars = payload
  },
}
export const getters = {
  getCircularById: (state) => (id) => {
    return state.allCirculars.find((circular) => circular.Id === id)
  },
  getCircular: (state, Id) => {
    return state.allCirculars.filter((circular) => circular.Id === Id)
  },
}

export const actions = {
  async getCirculars({ commit }) {
    let circulars = await fetch(`${this.$config.apiSfUrl}/circulars?$expand=resources&sf_culture=${this.$i18n.locale}`).then((res) => res.json())
    let data = circulars.value
    commit('SET_CIRCULARS', data)
  },
}
