export default (axios, context) => ({
  getEvents(options) {
    return axios.get('https://api2.dfm.ae/web/sf/v1/dfmevents', options)
  },
  getService(id) {
    return axios.get('https://api2.dfm.ae/web/sf/v1/services', {
      params: {
        $filter: `Id eq ${id}`,
      },
    })
  },
  getZakat() {
    return axios.post(`${this.$config.apiBaseUrl}/data`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: 'Command=GetZakatCalculatorDetails&Symbol=DFM',
    })
  },
  getServicesCatalogue() {
    return axios.post(`${this.$config.apiBaseUrl}/data`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: 'Command=BuildServiceCatalogObject&Organization=DFM',
    })
  },
  getDividends(year) {
    return axios.post('/api', {
      // method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `Command=getdividendsconfig&year=${year}`,
    })
  },
  getStocks(options) {
    return axios.get('https://api2.dfm.ae/mw/v1/stocks', options)
  },

  getDisclosures(options) {
    return axios.get('//feeds.dfm.ae/sso/source?p_url=prototype_efsah', options)
  },

  getIntegratedReports(options) {
    return axios.get('//feeds.dfm.ae/sso/source/prototype_efsah?types=integrated_reports', options)
  },
  getDisclosuresCount(options) {
    return axios.get('//feeds.dfm.ae/sso/source?p_url=efsah_count', options)
  },
})
