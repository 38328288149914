import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5878018a = () => interopDefault(import('../pages/app-ivestor.vue' /* webpackChunkName: "pages/app-ivestor" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _37dec224 = () => interopDefault(import('../pages/dfm-mobile-app.vue' /* webpackChunkName: "pages/dfm-mobile-app" */))
const _470dd7a6 = () => interopDefault(import('../pages/mwcarbon.vue' /* webpackChunkName: "pages/mwcarbon" */))
const _3e97ae24 = () => interopDefault(import('../pages/reg.vue' /* webpackChunkName: "pages/reg" */))
const _6e952be6 = () => interopDefault(import('../pages/updateinfo.vue' /* webpackChunkName: "pages/updateinfo" */))
const _3078a0d4 = () => interopDefault(import('../pages/discover-dfm/board-members-and-management.vue' /* webpackChunkName: "pages/discover-dfm/board-members-and-management" */))
const _487e68fa = () => interopDefault(import('../pages/discover-dfm/careers.vue' /* webpackChunkName: "pages/discover-dfm/careers" */))
const _3e778e0d = () => interopDefault(import('../pages/discover-dfm/contact-us.vue' /* webpackChunkName: "pages/discover-dfm/contact-us" */))
const _5b5fc027 = () => interopDefault(import('../pages/discover-dfm/dubai.vue' /* webpackChunkName: "pages/discover-dfm/dubai" */))
const _049a55c6 = () => interopDefault(import('../pages/discover-dfm/esg-sustainability.vue' /* webpackChunkName: "pages/discover-dfm/esg-sustainability" */))
const _3bf94ba7 = () => interopDefault(import('../pages/discover-dfm/investor-relations/index.vue' /* webpackChunkName: "pages/discover-dfm/investor-relations/index" */))
const _a06c88e8 = () => interopDefault(import('../pages/discover-dfm/news-media.vue' /* webpackChunkName: "pages/discover-dfm/news-media" */))
const _89e23aa2 = () => interopDefault(import('../pages/discover-dfm/news-media/events/index.vue' /* webpackChunkName: "pages/discover-dfm/news-media/events/index" */))
const _a816dcac = () => interopDefault(import('../pages/discover-dfm/news-media/media-kit.vue' /* webpackChunkName: "pages/discover-dfm/news-media/media-kit" */))
const _5b50b1ec = () => interopDefault(import('../pages/discover-dfm/news-media/press-releases/index.vue' /* webpackChunkName: "pages/discover-dfm/news-media/press-releases/index" */))
const _85f87652 = () => interopDefault(import('../pages/discover-dfm/news-media/events/_id.vue' /* webpackChunkName: "pages/discover-dfm/news-media/events/_id" */))
const _fe0b8158 = () => interopDefault(import('../pages/discover-dfm/news-media/press-releases/_id.vue' /* webpackChunkName: "pages/discover-dfm/news-media/press-releases/_id" */))
const _368922f4 = () => interopDefault(import('../pages/discover-dfm/why-dfm.vue' /* webpackChunkName: "pages/discover-dfm/why-dfm" */))
const _5511dc89 = () => interopDefault(import('../pages/investing/institutional-investors.vue' /* webpackChunkName: "pages/investing/institutional-investors" */))
const _717c23b4 = () => interopDefault(import('../pages/investing/personal-investors.vue' /* webpackChunkName: "pages/investing/personal-investors" */))
const _14c0077e = () => interopDefault(import('../pages/members/brokers-directory.vue' /* webpackChunkName: "pages/members/brokers-directory" */))
const _7ba99307 = () => interopDefault(import('../pages/members/brokers-ranking.vue' /* webpackChunkName: "pages/members/brokers-ranking" */))
const _b74ae070 = () => interopDefault(import('../pages/members/dfm-brokers-members.vue' /* webpackChunkName: "pages/members/dfm-brokers-members" */))
const _7ea011fd = () => interopDefault(import('../pages/members/direct-market-access.vue' /* webpackChunkName: "pages/members/direct-market-access" */))
const _13637c78 = () => interopDefault(import('../pages/members/market-makers.vue' /* webpackChunkName: "pages/members/market-makers" */))
const _34d37bd8 = () => interopDefault(import('../pages/members/member-services/index.vue' /* webpackChunkName: "pages/members/member-services/index" */))
const _4a2aae73 = () => interopDefault(import('../pages/members/membership-options.vue' /* webpackChunkName: "pages/members/membership-options" */))
const _05abd2c8 = () => interopDefault(import('../pages/members/registered-trading-system-vendors.vue' /* webpackChunkName: "pages/members/registered-trading-system-vendors" */))
const _2b0031e0 = () => interopDefault(import('../pages/other/disclaimer.vue' /* webpackChunkName: "pages/other/disclaimer" */))
const _26a40cfd = () => interopDefault(import('../pages/other/news-details.vue' /* webpackChunkName: "pages/other/news-details" */))
const _88d97fe8 = () => interopDefault(import('../pages/other/privacy-policy.vue' /* webpackChunkName: "pages/other/privacy-policy" */))
const _64fe2f9d = () => interopDefault(import('../pages/other/termsandconditions.vue' /* webpackChunkName: "pages/other/termsandconditions" */))
const _c4841734 = () => interopDefault(import('../pages/registrations/derivatives-training.vue' /* webpackChunkName: "pages/registrations/derivatives-training" */))
const _3ea61288 = () => interopDefault(import('../pages/registrations/dubai-reit-market.vue' /* webpackChunkName: "pages/registrations/dubai-reit-market" */))
const _cf1b8e9e = () => interopDefault(import('../pages/registrations/suhoor-gathering.vue' /* webpackChunkName: "pages/registrations/suhoor-gathering" */))
const _8240f394 = () => interopDefault(import('../pages/discover-dfm/investor-relations/key-figures.vue' /* webpackChunkName: "pages/discover-dfm/investor-relations/key-figures" */))
const _2ae97160 = () => interopDefault(import('../pages/discover-dfm/investor-relations/reports-presentations.vue' /* webpackChunkName: "pages/discover-dfm/investor-relations/reports-presentations" */))
const _8aa1dd46 = () => interopDefault(import('../pages/investing/products/carbon-credits.vue' /* webpackChunkName: "pages/investing/products/carbon-credits" */))
const _61507573 = () => interopDefault(import('../pages/investing/products/carbon-credits/detail.vue' /* webpackChunkName: "pages/investing/products/carbon-credits/detail" */))
const _6f7763c0 = () => interopDefault(import('../pages/investing/products/carbon-credits/hello.vue' /* webpackChunkName: "pages/investing/products/carbon-credits/hello" */))
const _76106866 = () => interopDefault(import('../pages/investing/products/carbon-credits/join-the-pilot-form.vue' /* webpackChunkName: "pages/investing/products/carbon-credits/join-the-pilot-form" */))
const _529d6888 = () => interopDefault(import('../pages/investing/products/carbon-credits/_id.vue' /* webpackChunkName: "pages/investing/products/carbon-credits/_id" */))
const _d369cff6 = () => interopDefault(import('../pages/investing/products/carbon-credits/_symbol.vue' /* webpackChunkName: "pages/investing/products/carbon-credits/_symbol" */))
const _232034b9 = () => interopDefault(import('../pages/investing/products/derivatives.vue' /* webpackChunkName: "pages/investing/products/derivatives" */))
const _558b5530 = () => interopDefault(import('../pages/investing/products/equity.vue' /* webpackChunkName: "pages/investing/products/equity" */))
const _610d57c0 = () => interopDefault(import('../pages/investing/products/etfs-funds.vue' /* webpackChunkName: "pages/investing/products/etfs-funds" */))
const _630794d8 = () => interopDefault(import('../pages/investing/products/indices.vue' /* webpackChunkName: "pages/investing/products/indices" */))
const _16924afc = () => interopDefault(import('../pages/investing/products/oil-futures.vue' /* webpackChunkName: "pages/investing/products/oil-futures" */))
const _cba7247c = () => interopDefault(import('../pages/investing/products/reits.vue' /* webpackChunkName: "pages/investing/products/reits" */))
const _158fce77 = () => interopDefault(import('../pages/investing/products/single-stock-futures.vue' /* webpackChunkName: "pages/investing/products/single-stock-futures" */))
const _9f2f0162 = () => interopDefault(import('../pages/investing/products/sukuk-bonds.vue' /* webpackChunkName: "pages/investing/products/sukuk-bonds" */))
const _16db09ae = () => interopDefault(import('../pages/investing/products/union-coop.vue' /* webpackChunkName: "pages/investing/products/union-coop" */))
const _b69dfcfe = () => interopDefault(import('../pages/investing/services/dfm-pjsc-unclaimed-dividends.vue' /* webpackChunkName: "pages/investing/services/dfm-pjsc-unclaimed-dividends" */))
const _40f4946e = () => interopDefault(import('../pages/investing/services/dividend-distribution.vue' /* webpackChunkName: "pages/investing/services/dividend-distribution" */))
const _6691a618 = () => interopDefault(import('../pages/investing/services/how-to-trade.vue' /* webpackChunkName: "pages/investing/services/how-to-trade" */))
const _ac943f74 = () => interopDefault(import('../pages/investing/services/investor-information-update.vue' /* webpackChunkName: "pages/investing/services/investor-information-update" */))
const _9ae89e8a = () => interopDefault(import('../pages/investing/services/ipo-subscriptions/index.vue' /* webpackChunkName: "pages/investing/services/ipo-subscriptions/index" */))
const _1834d443 = () => interopDefault(import('../pages/investing/services/ivestor.vue' /* webpackChunkName: "pages/investing/services/ivestor" */))
const _69a3b30e = () => interopDefault(import('../pages/investing/services/ivestor/apple.vue' /* webpackChunkName: "pages/investing/services/ivestor/apple" */))
const _2c10001a = () => interopDefault(import('../pages/investing/services/ivestor/notification-form.vue' /* webpackChunkName: "pages/investing/services/ivestor/notification-form" */))
const _3164f4ab = () => interopDefault(import('../pages/investing/services/ivestor-apple-pay.vue' /* webpackChunkName: "pages/investing/services/ivestor-apple-pay" */))
const _184144df = () => interopDefault(import('../pages/investing/services/mobile-app.vue' /* webpackChunkName: "pages/investing/services/mobile-app" */))
const _537354e4 = () => interopDefault(import('../pages/investing/services/mobile-app/reg.vue' /* webpackChunkName: "pages/investing/services/mobile-app/reg" */))
const _3600fd57 = () => interopDefault(import('../pages/investing/services/mobile-app/updateinfo.vue' /* webpackChunkName: "pages/investing/services/mobile-app/updateinfo" */))
const _37a0aafd = () => interopDefault(import('../pages/investing/services/pjsc-zakat.vue' /* webpackChunkName: "pages/investing/services/pjsc-zakat" */))
const _2a2cdd24 = () => interopDefault(import('../pages/investing/services/rights-issue-subscription.vue' /* webpackChunkName: "pages/investing/services/rights-issue-subscription" */))
const _38a3ecef = () => interopDefault(import('../pages/issuers/listed-securities/company-profile-page.vue' /* webpackChunkName: "pages/issuers/listed-securities/company-profile-page" */))
const _2ccac863 = () => interopDefault(import('../pages/raise-capital/ipo-listings/dual-listings.vue' /* webpackChunkName: "pages/raise-capital/ipo-listings/dual-listings" */))
const _360507a9 = () => interopDefault(import('../pages/raise-capital/ipo-listings/established-companies.vue' /* webpackChunkName: "pages/raise-capital/ipo-listings/established-companies" */))
const _fd6d1d8c = () => interopDefault(import('../pages/raise-capital/ipo-listings/family-businesses.vue' /* webpackChunkName: "pages/raise-capital/ipo-listings/family-businesses" */))
const _728739f3 = () => interopDefault(import('../pages/raise-capital/ipo-listings/free-zones.vue' /* webpackChunkName: "pages/raise-capital/ipo-listings/free-zones" */))
const _5f9d3386 = () => interopDefault(import('../pages/raise-capital/ipo-listings/overview.vue' /* webpackChunkName: "pages/raise-capital/ipo-listings/overview" */))
const _1a628fd4 = () => interopDefault(import('../pages/raise-capital/ipo-listings/private-companies.vue' /* webpackChunkName: "pages/raise-capital/ipo-listings/private-companies" */))
const _93101564 = () => interopDefault(import('../pages/raise-capital/ipo-listings/sme.vue' /* webpackChunkName: "pages/raise-capital/ipo-listings/sme" */))
const _5029a4e4 = () => interopDefault(import('../pages/raise-capital/listing-options/listing-equities.vue' /* webpackChunkName: "pages/raise-capital/listing-options/listing-equities" */))
const _69a6c67e = () => interopDefault(import('../pages/raise-capital/listing-options/listing-etfs-funds.vue' /* webpackChunkName: "pages/raise-capital/listing-options/listing-etfs-funds" */))
const _6fc70eb8 = () => interopDefault(import('../pages/raise-capital/listing-options/listing-reits.vue' /* webpackChunkName: "pages/raise-capital/listing-options/listing-reits" */))
const _5be958b1 = () => interopDefault(import('../pages/raise-capital/listing-options/listing-sukuk-bonds.vue' /* webpackChunkName: "pages/raise-capital/listing-options/listing-sukuk-bonds" */))
const _a0c0905a = () => interopDefault(import('../pages/raise-capital/listing-services/arena-platform.vue' /* webpackChunkName: "pages/raise-capital/listing-services/arena-platform" */))
const _4f1bdce6 = () => interopDefault(import('../pages/raise-capital/listing-services/international-roadshows.vue' /* webpackChunkName: "pages/raise-capital/listing-services/international-roadshows" */))
const _5a38b02a = () => interopDefault(import('../pages/raise-capital/listing-services/ipo-accelerator-programme.vue' /* webpackChunkName: "pages/raise-capital/listing-services/ipo-accelerator-programme" */))
const _c45d3402 = () => interopDefault(import('../pages/raise-capital/listing-services/registry-service.vue' /* webpackChunkName: "pages/raise-capital/listing-services/registry-service" */))
const _3cb2729d = () => interopDefault(import('../pages/the-exchange/market-information/direct-deals.vue' /* webpackChunkName: "pages/the-exchange/market-information/direct-deals" */))
const _0815b29d = () => interopDefault(import('../pages/the-exchange/market-information/indices.vue' /* webpackChunkName: "pages/the-exchange/market-information/indices" */))
const _7e870f60 = () => interopDefault(import('../pages/the-exchange/market-information/indices/index.vue' /* webpackChunkName: "pages/the-exchange/market-information/indices/index" */))
const _78889646 = () => interopDefault(import('../pages/the-exchange/market-information/indices/_currentIndex.vue' /* webpackChunkName: "pages/the-exchange/market-information/indices/_currentIndex" */))
const _30e87be4 = () => interopDefault(import('../pages/the-exchange/market-information/listed-securities.vue' /* webpackChunkName: "pages/the-exchange/market-information/listed-securities" */))
const _1b3eada5 = () => interopDefault(import('../pages/the-exchange/market-information/listed-securities/bonds.vue' /* webpackChunkName: "pages/the-exchange/market-information/listed-securities/bonds" */))
const _5a3a4dc4 = () => interopDefault(import('../pages/the-exchange/market-information/listed-securities/equities.vue' /* webpackChunkName: "pages/the-exchange/market-information/listed-securities/equities" */))
const _1b4cbd40 = () => interopDefault(import('../pages/the-exchange/market-information/listed-securities/exchange-traded-funds.vue' /* webpackChunkName: "pages/the-exchange/market-information/listed-securities/exchange-traded-funds" */))
const _48d1123a = () => interopDefault(import('../pages/the-exchange/market-information/listed-securities/funds.vue' /* webpackChunkName: "pages/the-exchange/market-information/listed-securities/funds" */))
const _9a4cc5ea = () => interopDefault(import('../pages/the-exchange/market-information/listed-securities/real-estate-investment-trust.vue' /* webpackChunkName: "pages/the-exchange/market-information/listed-securities/real-estate-investment-trust" */))
const _3e1fe298 = () => interopDefault(import('../pages/the-exchange/market-information/listed-securities/sukuk.vue' /* webpackChunkName: "pages/the-exchange/market-information/listed-securities/sukuk" */))
const _11444f75 = () => interopDefault(import('../pages/the-exchange/market-information/marketdata-providers.vue' /* webpackChunkName: "pages/the-exchange/market-information/marketdata-providers" */))
const _689986dc = () => interopDefault(import('../pages/the-exchange/news-disclosures/corporate-actions.vue' /* webpackChunkName: "pages/the-exchange/news-disclosures/corporate-actions" */))
const _6e3fbfe8 = () => interopDefault(import('../pages/the-exchange/news-disclosures/derivatives-market-announcements/index.vue' /* webpackChunkName: "pages/the-exchange/news-disclosures/derivatives-market-announcements/index" */))
const _6e69eaf0 = () => interopDefault(import('../pages/the-exchange/news-disclosures/disclosures/index.vue' /* webpackChunkName: "pages/the-exchange/news-disclosures/disclosures/index" */))
const _3d9e2d05 = () => interopDefault(import('../pages/the-exchange/news-disclosures/e-board.vue' /* webpackChunkName: "pages/the-exchange/news-disclosures/e-board" */))
const _5b961460 = () => interopDefault(import('../pages/the-exchange/news-disclosures/e-board/positions.vue' /* webpackChunkName: "pages/the-exchange/news-disclosures/e-board/positions" */))
const _72f6eb43 = () => interopDefault(import('../pages/the-exchange/news-disclosures/e-board/track-nominations.vue' /* webpackChunkName: "pages/the-exchange/news-disclosures/e-board/track-nominations" */))
const _38b65f47 = () => interopDefault(import('../pages/the-exchange/news-disclosures/market-announcements/index.vue' /* webpackChunkName: "pages/the-exchange/news-disclosures/market-announcements/index" */))
const _42dc1911 = () => interopDefault(import('../pages/the-exchange/news-disclosures/settlement-trading-calender.vue' /* webpackChunkName: "pages/the-exchange/news-disclosures/settlement-trading-calender" */))
const _472624bc = () => interopDefault(import('../pages/the-exchange/regulation/circulars/index.vue' /* webpackChunkName: "pages/the-exchange/regulation/circulars/index" */))
const _3bf11c1e = () => interopDefault(import('../pages/the-exchange/regulation/market-rules.vue' /* webpackChunkName: "pages/the-exchange/regulation/market-rules" */))
const _8d2b80a2 = () => interopDefault(import('../pages/the-exchange/regulation/sharia-compliance.vue' /* webpackChunkName: "pages/the-exchange/regulation/sharia-compliance" */))
const _d2f565c8 = () => interopDefault(import('../pages/the-exchange/statistics-reports/bulletins.vue' /* webpackChunkName: "pages/the-exchange/statistics-reports/bulletins" */))
const _bc25d712 = () => interopDefault(import('../pages/the-exchange/statistics-reports/classification-list-page.vue' /* webpackChunkName: "pages/the-exchange/statistics-reports/classification-list-page" */))
const _6fac8a26 = () => interopDefault(import('../pages/the-exchange/statistics-reports/foreign-ownership.vue' /* webpackChunkName: "pages/the-exchange/statistics-reports/foreign-ownership" */))
const _ab4b8c86 = () => interopDefault(import('../pages/the-exchange/statistics-reports/historical-data.vue' /* webpackChunkName: "pages/the-exchange/statistics-reports/historical-data" */))
const _532b01dc = () => interopDefault(import('../pages/the-exchange/statistics-reports/historical-data/company-prices.vue' /* webpackChunkName: "pages/the-exchange/statistics-reports/historical-data/company-prices" */))
const _1e20927b = () => interopDefault(import('../pages/the-exchange/statistics-reports/historical-data/dfmgi.vue' /* webpackChunkName: "pages/the-exchange/statistics-reports/historical-data/dfmgi" */))
const _ef967784 = () => interopDefault(import('../pages/the-exchange/statistics-reports/historical-data/futures.vue' /* webpackChunkName: "pages/the-exchange/statistics-reports/historical-data/futures" */))
const _8bcb20fc = () => interopDefault(import('../pages/the-exchange/statistics-reports/historical-data/sector-indices.vue' /* webpackChunkName: "pages/the-exchange/statistics-reports/historical-data/sector-indices" */))
const _5b16f15e = () => interopDefault(import('../pages/the-exchange/statistics-reports/historical-data/trade-by-client-type.vue' /* webpackChunkName: "pages/the-exchange/statistics-reports/historical-data/trade-by-client-type" */))
const _6755fdde = () => interopDefault(import('../pages/the-exchange/statistics-reports/historical-data/trade-by-nationality.vue' /* webpackChunkName: "pages/the-exchange/statistics-reports/historical-data/trade-by-nationality" */))
const _350d66cb = () => interopDefault(import('../pages/the-exchange/statistics-reports/ownership-holding-statistics.vue' /* webpackChunkName: "pages/the-exchange/statistics-reports/ownership-holding-statistics" */))
const _bbca8714 = () => interopDefault(import('../pages/the-exchange/statistics-reports/sharia-classification-list.vue' /* webpackChunkName: "pages/the-exchange/statistics-reports/sharia-classification-list" */))
const _96fd035a = () => interopDefault(import('../pages/the-exchange/statistics-reports/weekly-margin-trading-report.vue' /* webpackChunkName: "pages/the-exchange/statistics-reports/weekly-margin-trading-report" */))
const _7ec24e50 = () => interopDefault(import('../pages/issuers/listed-securities/securities/company-profile-page.vue' /* webpackChunkName: "pages/issuers/listed-securities/securities/company-profile-page" */))
const _95150706 = () => interopDefault(import('../pages/investing/services/ipo-subscriptions/_IPOCode.vue' /* webpackChunkName: "pages/investing/services/ipo-subscriptions/_IPOCode" */))
const _3ae184ca = () => interopDefault(import('../pages/the-exchange/market-information/company/_SecuritySymbol.vue' /* webpackChunkName: "pages/the-exchange/market-information/company/_SecuritySymbol" */))
const _5ba81321 = () => interopDefault(import('../pages/the-exchange/market-information/company/_SecuritySymbol/corporate-actions.vue' /* webpackChunkName: "pages/the-exchange/market-information/company/_SecuritySymbol/corporate-actions" */))
const _27258701 = () => interopDefault(import('../pages/the-exchange/market-information/company/_SecuritySymbol/general-meetings.vue' /* webpackChunkName: "pages/the-exchange/market-information/company/_SecuritySymbol/general-meetings" */))
const _0d2116d0 = () => interopDefault(import('../pages/the-exchange/market-information/company/_SecuritySymbol/news-disclosures.vue' /* webpackChunkName: "pages/the-exchange/market-information/company/_SecuritySymbol/news-disclosures" */))
const _43ae7435 = () => interopDefault(import('../pages/the-exchange/market-information/company/_SecuritySymbol/profile.vue' /* webpackChunkName: "pages/the-exchange/market-information/company/_SecuritySymbol/profile" */))
const _2b70414b = () => interopDefault(import('../pages/the-exchange/market-information/company/_SecuritySymbol/reports.vue' /* webpackChunkName: "pages/the-exchange/market-information/company/_SecuritySymbol/reports" */))
const _a17298a6 = () => interopDefault(import('../pages/the-exchange/market-information/company/_SecuritySymbol/trading.vue' /* webpackChunkName: "pages/the-exchange/market-information/company/_SecuritySymbol/trading" */))
const _cae1b4e0 = () => interopDefault(import('../pages/the-exchange/market-information/company/_SecuritySymbol/trading/daily-summary.vue' /* webpackChunkName: "pages/the-exchange/market-information/company/_SecuritySymbol/trading/daily-summary" */))
const _4a15d9b6 = () => interopDefault(import('../pages/the-exchange/market-information/company/_SecuritySymbol/trading/foreign-investments.vue' /* webpackChunkName: "pages/the-exchange/market-information/company/_SecuritySymbol/trading/foreign-investments" */))
const _1982fb1a = () => interopDefault(import('../pages/the-exchange/market-information/company/_SecuritySymbol/trading/nav-summary.vue' /* webpackChunkName: "pages/the-exchange/market-information/company/_SecuritySymbol/trading/nav-summary" */))
const _77b8945c = () => interopDefault(import('../pages/the-exchange/market-information/company/_SecuritySymbol/trading/top-shareholders.vue' /* webpackChunkName: "pages/the-exchange/market-information/company/_SecuritySymbol/trading/top-shareholders" */))
const _77485718 = () => interopDefault(import('../pages/the-exchange/market-information/company/_SecuritySymbol/trading/trading-summary.vue' /* webpackChunkName: "pages/the-exchange/market-information/company/_SecuritySymbol/trading/trading-summary" */))
const _61e6c760 = () => interopDefault(import('../pages/the-exchange/news-disclosures/derivatives-market-announcements/_id.vue' /* webpackChunkName: "pages/the-exchange/news-disclosures/derivatives-market-announcements/_id" */))
const _07542d50 = () => interopDefault(import('../pages/the-exchange/news-disclosures/disclosures/_id.vue' /* webpackChunkName: "pages/the-exchange/news-disclosures/disclosures/_id" */))
const _f1dcf122 = () => interopDefault(import('../pages/the-exchange/news-disclosures/market-announcements/_id.vue' /* webpackChunkName: "pages/the-exchange/news-disclosures/market-announcements/_id" */))
const _2ba3b3b8 = () => interopDefault(import('../pages/the-exchange/regulation/circulars/_id.vue' /* webpackChunkName: "pages/the-exchange/regulation/circulars/_id" */))
const _63a38060 = () => interopDefault(import('../pages/members/member-services/_Id.vue' /* webpackChunkName: "pages/members/member-services/_Id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/app-ivestor",
    component: _5878018a,
    name: "app-ivestor___en___default"
  }, {
    path: "/ar",
    component: _98173ca8,
    name: "index___ar"
  }, {
    path: "/dfm-mobile-app",
    component: _37dec224,
    name: "dfm-mobile-app___en___default"
  }, {
    path: "/en",
    component: _98173ca8,
    name: "index___en"
  }, {
    path: "/mwcarbon",
    component: _470dd7a6,
    name: "mwcarbon___en___default"
  }, {
    path: "/reg",
    component: _3e97ae24,
    name: "reg___en___default"
  }, {
    path: "/updateinfo",
    component: _6e952be6,
    name: "updateinfo___en___default"
  }, {
    path: "/ar/app-ivestor",
    component: _5878018a,
    name: "app-ivestor___ar"
  }, {
    path: "/ar/dfm-mobile-app",
    component: _37dec224,
    name: "dfm-mobile-app___ar"
  }, {
    path: "/ar/mwcarbon",
    component: _470dd7a6,
    name: "mwcarbon___ar"
  }, {
    path: "/ar/reg",
    component: _3e97ae24,
    name: "reg___ar"
  }, {
    path: "/ar/updateinfo",
    component: _6e952be6,
    name: "updateinfo___ar"
  }, {
    path: "/discover-dfm/board-members-and-management",
    component: _3078a0d4,
    name: "discover-dfm-board-members-and-management___en___default"
  }, {
    path: "/discover-dfm/careers",
    component: _487e68fa,
    name: "discover-dfm-careers___en___default"
  }, {
    path: "/discover-dfm/contact-us",
    component: _3e778e0d,
    name: "discover-dfm-contact-us___en___default"
  }, {
    path: "/discover-dfm/dubai",
    component: _5b5fc027,
    name: "discover-dfm-dubai___en___default"
  }, {
    path: "/discover-dfm/esg-sustainability",
    component: _049a55c6,
    name: "discover-dfm-esg-sustainability___en___default"
  }, {
    path: "/discover-dfm/investor-relations",
    component: _3bf94ba7,
    name: "discover-dfm-investor-relations___en___default"
  }, {
    path: "/discover-dfm/news-media",
    component: _a06c88e8,
    name: "discover-dfm-news-media___en___default",
    children: [{
      path: "events",
      component: _89e23aa2,
      name: "discover-dfm-news-media-events___en___default"
    }, {
      path: "media-kit",
      component: _a816dcac,
      name: "discover-dfm-news-media-media-kit___en___default"
    }, {
      path: "press-releases",
      component: _5b50b1ec,
      name: "discover-dfm-news-media-press-releases___en___default"
    }, {
      path: "events/:id",
      component: _85f87652,
      name: "discover-dfm-news-media-events-id___en___default"
    }, {
      path: "press-releases/:id?",
      component: _fe0b8158,
      name: "discover-dfm-news-media-press-releases-id___en___default"
    }]
  }, {
    path: "/discover-dfm/why-dfm",
    component: _368922f4,
    name: "discover-dfm-why-dfm___en___default"
  }, {
    path: "/en/app-ivestor",
    component: _5878018a,
    name: "app-ivestor___en"
  }, {
    path: "/en/dfm-mobile-app",
    component: _37dec224,
    name: "dfm-mobile-app___en"
  }, {
    path: "/en/mwcarbon",
    component: _470dd7a6,
    name: "mwcarbon___en"
  }, {
    path: "/en/reg",
    component: _3e97ae24,
    name: "reg___en"
  }, {
    path: "/en/updateinfo",
    component: _6e952be6,
    name: "updateinfo___en"
  }, {
    path: "/investing/institutional-investors",
    component: _5511dc89,
    name: "investing-institutional-investors___en___default"
  }, {
    path: "/investing/personal-investors",
    component: _717c23b4,
    name: "investing-personal-investors___en___default"
  }, {
    path: "/members/brokers-directory",
    component: _14c0077e,
    name: "members-brokers-directory___en___default"
  }, {
    path: "/members/brokers-ranking",
    component: _7ba99307,
    name: "members-brokers-ranking___en___default"
  }, {
    path: "/members/dfm-brokers-members",
    component: _b74ae070,
    name: "members-dfm-brokers-members___en___default"
  }, {
    path: "/members/direct-market-access",
    component: _7ea011fd,
    name: "members-direct-market-access___en___default"
  }, {
    path: "/members/market-makers",
    component: _13637c78,
    name: "members-market-makers___en___default"
  }, {
    path: "/members/member-services",
    component: _34d37bd8,
    name: "members-member-services___en___default"
  }, {
    path: "/members/membership-options",
    component: _4a2aae73,
    name: "members-membership-options___en___default"
  }, {
    path: "/members/registered-trading-system-vendors",
    component: _05abd2c8,
    name: "members-registered-trading-system-vendors___en___default"
  }, {
    path: "/other/disclaimer",
    component: _2b0031e0,
    name: "other-disclaimer___en___default"
  }, {
    path: "/other/news-details",
    component: _26a40cfd,
    name: "other-news-details___en___default"
  }, {
    path: "/other/privacy-policy",
    component: _88d97fe8,
    name: "other-privacy-policy___en___default"
  }, {
    path: "/other/termsandconditions",
    component: _64fe2f9d,
    name: "other-termsandconditions___en___default"
  }, {
    path: "/registrations/derivatives-training",
    component: _c4841734,
    name: "registrations-derivatives-training___en___default"
  }, {
    path: "/registrations/dubai-reit-market",
    component: _3ea61288,
    name: "registrations-dubai-reit-market___en___default"
  }, {
    path: "/registrations/suhoor-gathering",
    component: _cf1b8e9e,
    name: "registrations-suhoor-gathering___en___default"
  }, {
    path: "/ar/discover-dfm/board-members-and-management",
    component: _3078a0d4,
    name: "discover-dfm-board-members-and-management___ar"
  }, {
    path: "/ar/discover-dfm/careers",
    component: _487e68fa,
    name: "discover-dfm-careers___ar"
  }, {
    path: "/ar/discover-dfm/contact-us",
    component: _3e778e0d,
    name: "discover-dfm-contact-us___ar"
  }, {
    path: "/ar/discover-dfm/dubai",
    component: _5b5fc027,
    name: "discover-dfm-dubai___ar"
  }, {
    path: "/ar/discover-dfm/esg-sustainability",
    component: _049a55c6,
    name: "discover-dfm-esg-sustainability___ar"
  }, {
    path: "/ar/discover-dfm/investor-relations",
    component: _3bf94ba7,
    name: "discover-dfm-investor-relations___ar"
  }, {
    path: "/ar/discover-dfm/news-media",
    component: _a06c88e8,
    name: "discover-dfm-news-media___ar",
    children: [{
      path: "events",
      component: _89e23aa2,
      name: "discover-dfm-news-media-events___ar"
    }, {
      path: "media-kit",
      component: _a816dcac,
      name: "discover-dfm-news-media-media-kit___ar"
    }, {
      path: "press-releases",
      component: _5b50b1ec,
      name: "discover-dfm-news-media-press-releases___ar"
    }, {
      path: "events/:id",
      component: _85f87652,
      name: "discover-dfm-news-media-events-id___ar"
    }, {
      path: "press-releases/:id?",
      component: _fe0b8158,
      name: "discover-dfm-news-media-press-releases-id___ar"
    }]
  }, {
    path: "/ar/discover-dfm/why-dfm",
    component: _368922f4,
    name: "discover-dfm-why-dfm___ar"
  }, {
    path: "/ar/investing/institutional-investors",
    component: _5511dc89,
    name: "investing-institutional-investors___ar"
  }, {
    path: "/ar/investing/personal-investors",
    component: _717c23b4,
    name: "investing-personal-investors___ar"
  }, {
    path: "/ar/members/brokers-directory",
    component: _14c0077e,
    name: "members-brokers-directory___ar"
  }, {
    path: "/ar/members/brokers-ranking",
    component: _7ba99307,
    name: "members-brokers-ranking___ar"
  }, {
    path: "/ar/members/dfm-brokers-members",
    component: _b74ae070,
    name: "members-dfm-brokers-members___ar"
  }, {
    path: "/ar/members/direct-market-access",
    component: _7ea011fd,
    name: "members-direct-market-access___ar"
  }, {
    path: "/ar/members/market-makers",
    component: _13637c78,
    name: "members-market-makers___ar"
  }, {
    path: "/ar/members/member-services",
    component: _34d37bd8,
    name: "members-member-services___ar"
  }, {
    path: "/ar/members/membership-options",
    component: _4a2aae73,
    name: "members-membership-options___ar"
  }, {
    path: "/ar/members/registered-trading-system-vendors",
    component: _05abd2c8,
    name: "members-registered-trading-system-vendors___ar"
  }, {
    path: "/ar/other/disclaimer",
    component: _2b0031e0,
    name: "other-disclaimer___ar"
  }, {
    path: "/ar/other/news-details",
    component: _26a40cfd,
    name: "other-news-details___ar"
  }, {
    path: "/ar/other/privacy-policy",
    component: _88d97fe8,
    name: "other-privacy-policy___ar"
  }, {
    path: "/ar/other/termsandconditions",
    component: _64fe2f9d,
    name: "other-termsandconditions___ar"
  }, {
    path: "/ar/registrations/derivatives-training",
    component: _c4841734,
    name: "registrations-derivatives-training___ar"
  }, {
    path: "/ar/registrations/dubai-reit-market",
    component: _3ea61288,
    name: "registrations-dubai-reit-market___ar"
  }, {
    path: "/ar/registrations/suhoor-gathering",
    component: _cf1b8e9e,
    name: "registrations-suhoor-gathering___ar"
  }, {
    path: "/discover-dfm/investor-relations/key-figures",
    component: _8240f394,
    name: "discover-dfm-investor-relations-key-figures___en___default"
  }, {
    path: "/discover-dfm/investor-relations/reports-presentations",
    component: _2ae97160,
    name: "discover-dfm-investor-relations-reports-presentations___en___default"
  }, {
    path: "/en/discover-dfm/board-members-and-management",
    component: _3078a0d4,
    name: "discover-dfm-board-members-and-management___en"
  }, {
    path: "/en/discover-dfm/careers",
    component: _487e68fa,
    name: "discover-dfm-careers___en"
  }, {
    path: "/en/discover-dfm/contact-us",
    component: _3e778e0d,
    name: "discover-dfm-contact-us___en"
  }, {
    path: "/en/discover-dfm/dubai",
    component: _5b5fc027,
    name: "discover-dfm-dubai___en"
  }, {
    path: "/en/discover-dfm/esg-sustainability",
    component: _049a55c6,
    name: "discover-dfm-esg-sustainability___en"
  }, {
    path: "/en/discover-dfm/investor-relations",
    component: _3bf94ba7,
    name: "discover-dfm-investor-relations___en"
  }, {
    path: "/en/discover-dfm/news-media",
    component: _a06c88e8,
    name: "discover-dfm-news-media___en",
    children: [{
      path: "events",
      component: _89e23aa2,
      name: "discover-dfm-news-media-events___en"
    }, {
      path: "media-kit",
      component: _a816dcac,
      name: "discover-dfm-news-media-media-kit___en"
    }, {
      path: "press-releases",
      component: _5b50b1ec,
      name: "discover-dfm-news-media-press-releases___en"
    }, {
      path: "events/:id",
      component: _85f87652,
      name: "discover-dfm-news-media-events-id___en"
    }, {
      path: "press-releases/:id?",
      component: _fe0b8158,
      name: "discover-dfm-news-media-press-releases-id___en"
    }]
  }, {
    path: "/en/discover-dfm/why-dfm",
    component: _368922f4,
    name: "discover-dfm-why-dfm___en"
  }, {
    path: "/en/investing/institutional-investors",
    component: _5511dc89,
    name: "investing-institutional-investors___en"
  }, {
    path: "/en/investing/personal-investors",
    component: _717c23b4,
    name: "investing-personal-investors___en"
  }, {
    path: "/en/members/brokers-directory",
    component: _14c0077e,
    name: "members-brokers-directory___en"
  }, {
    path: "/en/members/brokers-ranking",
    component: _7ba99307,
    name: "members-brokers-ranking___en"
  }, {
    path: "/en/members/dfm-brokers-members",
    component: _b74ae070,
    name: "members-dfm-brokers-members___en"
  }, {
    path: "/en/members/direct-market-access",
    component: _7ea011fd,
    name: "members-direct-market-access___en"
  }, {
    path: "/en/members/market-makers",
    component: _13637c78,
    name: "members-market-makers___en"
  }, {
    path: "/en/members/member-services",
    component: _34d37bd8,
    name: "members-member-services___en"
  }, {
    path: "/en/members/membership-options",
    component: _4a2aae73,
    name: "members-membership-options___en"
  }, {
    path: "/en/members/registered-trading-system-vendors",
    component: _05abd2c8,
    name: "members-registered-trading-system-vendors___en"
  }, {
    path: "/en/other/disclaimer",
    component: _2b0031e0,
    name: "other-disclaimer___en"
  }, {
    path: "/en/other/news-details",
    component: _26a40cfd,
    name: "other-news-details___en"
  }, {
    path: "/en/other/privacy-policy",
    component: _88d97fe8,
    name: "other-privacy-policy___en"
  }, {
    path: "/en/other/termsandconditions",
    component: _64fe2f9d,
    name: "other-termsandconditions___en"
  }, {
    path: "/en/registrations/derivatives-training",
    component: _c4841734,
    name: "registrations-derivatives-training___en"
  }, {
    path: "/en/registrations/dubai-reit-market",
    component: _3ea61288,
    name: "registrations-dubai-reit-market___en"
  }, {
    path: "/en/registrations/suhoor-gathering",
    component: _cf1b8e9e,
    name: "registrations-suhoor-gathering___en"
  }, {
    path: "/investing/products/carbon-credits",
    component: _8aa1dd46,
    name: "investing-products-carbon-credits___en___default",
    children: [{
      path: "detail",
      component: _61507573,
      name: "investing-products-carbon-credits-detail___en___default"
    }, {
      path: "hello",
      component: _6f7763c0,
      name: "investing-products-carbon-credits-hello___en___default"
    }, {
      path: "join-the-pilot-form",
      component: _76106866,
      name: "investing-products-carbon-credits-join-the-pilot-form___en___default"
    }, {
      path: ":id?",
      component: _529d6888,
      name: "investing-products-carbon-credits-id___en___default"
    }, {
      path: ":symbol?",
      component: _d369cff6,
      name: "investing-products-carbon-credits-symbol___en___default"
    }]
  }, {
    path: "/investing/products/derivatives",
    component: _232034b9,
    name: "investing-products-derivatives___en___default"
  }, {
    path: "/investing/products/equity",
    component: _558b5530,
    name: "investing-products-equity___en___default"
  }, {
    path: "/investing/products/etfs-funds",
    component: _610d57c0,
    name: "investing-products-etfs-funds___en___default"
  }, {
    path: "/investing/products/indices",
    component: _630794d8,
    name: "investing-products-indices___en___default"
  }, {
    path: "/investing/products/oil-futures",
    component: _16924afc,
    name: "investing-products-oil-futures___en___default"
  }, {
    path: "/investing/products/reits",
    component: _cba7247c,
    name: "investing-products-reits___en___default"
  }, {
    path: "/investing/products/single-stock-futures",
    component: _158fce77,
    name: "investing-products-single-stock-futures___en___default"
  }, {
    path: "/investing/products/sukuk-bonds",
    component: _9f2f0162,
    name: "investing-products-sukuk-bonds___en___default"
  }, {
    path: "/investing/products/union-coop",
    component: _16db09ae,
    name: "investing-products-union-coop___en___default"
  }, {
    path: "/investing/services/dfm-pjsc-unclaimed-dividends",
    component: _b69dfcfe,
    name: "investing-services-dfm-pjsc-unclaimed-dividends___en___default"
  }, {
    path: "/investing/services/dividend-distribution",
    component: _40f4946e,
    name: "investing-services-dividend-distribution___en___default"
  }, {
    path: "/investing/services/how-to-trade",
    component: _6691a618,
    name: "investing-services-how-to-trade___en___default"
  }, {
    path: "/investing/services/investor-information-update",
    component: _ac943f74,
    name: "investing-services-investor-information-update___en___default"
  }, {
    path: "/investing/services/ipo-subscriptions",
    component: _9ae89e8a,
    name: "investing-services-ipo-subscriptions___en___default"
  }, {
    path: "/investing/services/ivestor",
    component: _1834d443,
    name: "investing-services-ivestor___en___default",
    children: [{
      path: "apple",
      component: _69a3b30e,
      name: "investing-services-ivestor-apple___en___default"
    }, {
      path: "notification-form",
      component: _2c10001a,
      name: "investing-services-ivestor-notification-form___en___default"
    }]
  }, {
    path: "/investing/services/ivestor-apple-pay",
    component: _3164f4ab,
    name: "investing-services-ivestor-apple-pay___en___default"
  }, {
    path: "/investing/services/mobile-app",
    component: _184144df,
    name: "investing-services-mobile-app___en___default",
    children: [{
      path: "reg",
      component: _537354e4,
      name: "investing-services-mobile-app-reg___en___default"
    }, {
      path: "updateinfo",
      component: _3600fd57,
      name: "investing-services-mobile-app-updateinfo___en___default"
    }]
  }, {
    path: "/investing/services/pjsc-zakat",
    component: _37a0aafd,
    name: "investing-services-pjsc-zakat___en___default"
  }, {
    path: "/investing/services/rights-issue-subscription",
    component: _2a2cdd24,
    name: "investing-services-rights-issue-subscription___en___default"
  }, {
    path: "/issuers/listed-securities/company-profile-page",
    component: _38a3ecef,
    name: "issuers-listed-securities-company-profile-page___en___default"
  }, {
    path: "/raise-capital/ipo-listings/dual-listings",
    component: _2ccac863,
    name: "raise-capital-ipo-listings-dual-listings___en___default"
  }, {
    path: "/raise-capital/ipo-listings/established-companies",
    component: _360507a9,
    name: "raise-capital-ipo-listings-established-companies___en___default"
  }, {
    path: "/raise-capital/ipo-listings/family-businesses",
    component: _fd6d1d8c,
    name: "raise-capital-ipo-listings-family-businesses___en___default"
  }, {
    path: "/raise-capital/ipo-listings/free-zones",
    component: _728739f3,
    name: "raise-capital-ipo-listings-free-zones___en___default"
  }, {
    path: "/raise-capital/ipo-listings/overview",
    component: _5f9d3386,
    name: "raise-capital-ipo-listings-overview___en___default"
  }, {
    path: "/raise-capital/ipo-listings/private-companies",
    component: _1a628fd4,
    name: "raise-capital-ipo-listings-private-companies___en___default"
  }, {
    path: "/raise-capital/ipo-listings/sme",
    component: _93101564,
    name: "raise-capital-ipo-listings-sme___en___default"
  }, {
    path: "/raise-capital/listing-options/listing-equities",
    component: _5029a4e4,
    name: "raise-capital-listing-options-listing-equities___en___default"
  }, {
    path: "/raise-capital/listing-options/listing-etfs-funds",
    component: _69a6c67e,
    name: "raise-capital-listing-options-listing-etfs-funds___en___default"
  }, {
    path: "/raise-capital/listing-options/listing-reits",
    component: _6fc70eb8,
    name: "raise-capital-listing-options-listing-reits___en___default"
  }, {
    path: "/raise-capital/listing-options/listing-sukuk-bonds",
    component: _5be958b1,
    name: "raise-capital-listing-options-listing-sukuk-bonds___en___default"
  }, {
    path: "/raise-capital/listing-services/arena-platform",
    component: _a0c0905a,
    name: "raise-capital-listing-services-arena-platform___en___default"
  }, {
    path: "/raise-capital/listing-services/international-roadshows",
    component: _4f1bdce6,
    name: "raise-capital-listing-services-international-roadshows___en___default"
  }, {
    path: "/raise-capital/listing-services/ipo-accelerator-programme",
    component: _5a38b02a,
    name: "raise-capital-listing-services-ipo-accelerator-programme___en___default"
  }, {
    path: "/raise-capital/listing-services/registry-service",
    component: _c45d3402,
    name: "raise-capital-listing-services-registry-service___en___default"
  }, {
    path: "/the-exchange/market-information/direct-deals",
    component: _3cb2729d,
    name: "the-exchange-market-information-direct-deals___en___default"
  }, {
    path: "/the-exchange/market-information/indices",
    component: _0815b29d,
    children: [{
      path: "",
      component: _7e870f60,
      name: "the-exchange-market-information-indices___en___default"
    }, {
      path: ":currentIndex",
      component: _78889646,
      name: "the-exchange-market-information-indices-currentIndex___en___default"
    }]
  }, {
    path: "/the-exchange/market-information/listed-securities",
    component: _30e87be4,
    name: "the-exchange-market-information-listed-securities___en___default",
    children: [{
      path: "bonds",
      component: _1b3eada5,
      name: "the-exchange-market-information-listed-securities-bonds___en___default"
    }, {
      path: "equities",
      component: _5a3a4dc4,
      name: "the-exchange-market-information-listed-securities-equities___en___default"
    }, {
      path: "exchange-traded-funds",
      component: _1b4cbd40,
      name: "the-exchange-market-information-listed-securities-exchange-traded-funds___en___default"
    }, {
      path: "funds",
      component: _48d1123a,
      name: "the-exchange-market-information-listed-securities-funds___en___default"
    }, {
      path: "real-estate-investment-trust",
      component: _9a4cc5ea,
      name: "the-exchange-market-information-listed-securities-real-estate-investment-trust___en___default"
    }, {
      path: "sukuk",
      component: _3e1fe298,
      name: "the-exchange-market-information-listed-securities-sukuk___en___default"
    }]
  }, {
    path: "/the-exchange/market-information/marketdata-providers",
    component: _11444f75,
    name: "the-exchange-market-information-marketdata-providers___en___default"
  }, {
    path: "/the-exchange/news-disclosures/corporate-actions",
    component: _689986dc,
    name: "the-exchange-news-disclosures-corporate-actions___en___default"
  }, {
    path: "/the-exchange/news-disclosures/derivatives-market-announcements",
    component: _6e3fbfe8,
    name: "the-exchange-news-disclosures-derivatives-market-announcements___en___default"
  }, {
    path: "/the-exchange/news-disclosures/disclosures",
    component: _6e69eaf0,
    name: "the-exchange-news-disclosures-disclosures___en___default"
  }, {
    path: "/the-exchange/news-disclosures/e-board",
    component: _3d9e2d05,
    name: "the-exchange-news-disclosures-e-board___en___default",
    children: [{
      path: "positions",
      component: _5b961460,
      name: "the-exchange-news-disclosures-e-board-positions___en___default"
    }, {
      path: "track-nominations",
      component: _72f6eb43,
      name: "the-exchange-news-disclosures-e-board-track-nominations___en___default"
    }]
  }, {
    path: "/the-exchange/news-disclosures/market-announcements",
    component: _38b65f47,
    name: "the-exchange-news-disclosures-market-announcements___en___default"
  }, {
    path: "/the-exchange/news-disclosures/settlement-trading-calender",
    component: _42dc1911,
    name: "the-exchange-news-disclosures-settlement-trading-calender___en___default"
  }, {
    path: "/the-exchange/regulation/circulars",
    component: _472624bc,
    name: "the-exchange-regulation-circulars___en___default"
  }, {
    path: "/the-exchange/regulation/market-rules",
    component: _3bf11c1e,
    name: "the-exchange-regulation-market-rules___en___default"
  }, {
    path: "/the-exchange/regulation/sharia-compliance",
    component: _8d2b80a2,
    name: "the-exchange-regulation-sharia-compliance___en___default"
  }, {
    path: "/the-exchange/statistics-reports/bulletins",
    component: _d2f565c8,
    name: "the-exchange-statistics-reports-bulletins___en___default"
  }, {
    path: "/the-exchange/statistics-reports/classification-list-page",
    component: _bc25d712,
    name: "the-exchange-statistics-reports-classification-list-page___en___default"
  }, {
    path: "/the-exchange/statistics-reports/foreign-ownership",
    component: _6fac8a26,
    name: "the-exchange-statistics-reports-foreign-ownership___en___default"
  }, {
    path: "/the-exchange/statistics-reports/historical-data",
    component: _ab4b8c86,
    name: "the-exchange-statistics-reports-historical-data___en___default",
    children: [{
      path: "company-prices",
      component: _532b01dc,
      name: "the-exchange-statistics-reports-historical-data-company-prices___en___default"
    }, {
      path: "dfmgi",
      component: _1e20927b,
      name: "the-exchange-statistics-reports-historical-data-dfmgi___en___default"
    }, {
      path: "futures",
      component: _ef967784,
      name: "the-exchange-statistics-reports-historical-data-futures___en___default"
    }, {
      path: "sector-indices",
      component: _8bcb20fc,
      name: "the-exchange-statistics-reports-historical-data-sector-indices___en___default"
    }, {
      path: "trade-by-client-type",
      component: _5b16f15e,
      name: "the-exchange-statistics-reports-historical-data-trade-by-client-type___en___default"
    }, {
      path: "trade-by-nationality",
      component: _6755fdde,
      name: "the-exchange-statistics-reports-historical-data-trade-by-nationality___en___default"
    }]
  }, {
    path: "/the-exchange/statistics-reports/ownership-holding-statistics",
    component: _350d66cb,
    name: "the-exchange-statistics-reports-ownership-holding-statistics___en___default"
  }, {
    path: "/the-exchange/statistics-reports/sharia-classification-list",
    component: _bbca8714,
    name: "the-exchange-statistics-reports-sharia-classification-list___en___default"
  }, {
    path: "/the-exchange/statistics-reports/weekly-margin-trading-report",
    component: _96fd035a,
    name: "the-exchange-statistics-reports-weekly-margin-trading-report___en___default"
  }, {
    path: "/ar/discover-dfm/investor-relations/key-figures",
    component: _8240f394,
    name: "discover-dfm-investor-relations-key-figures___ar"
  }, {
    path: "/ar/discover-dfm/investor-relations/reports-presentations",
    component: _2ae97160,
    name: "discover-dfm-investor-relations-reports-presentations___ar"
  }, {
    path: "/ar/investing/products/carbon-credits",
    component: _8aa1dd46,
    name: "investing-products-carbon-credits___ar",
    children: [{
      path: "detail",
      component: _61507573,
      name: "investing-products-carbon-credits-detail___ar"
    }, {
      path: "hello",
      component: _6f7763c0,
      name: "investing-products-carbon-credits-hello___ar"
    }, {
      path: "join-the-pilot-form",
      component: _76106866,
      name: "investing-products-carbon-credits-join-the-pilot-form___ar"
    }, {
      path: ":id?",
      component: _529d6888,
      name: "investing-products-carbon-credits-id___ar"
    }, {
      path: ":symbol?",
      component: _d369cff6,
      name: "investing-products-carbon-credits-symbol___ar"
    }]
  }, {
    path: "/ar/investing/products/derivatives",
    component: _232034b9,
    name: "investing-products-derivatives___ar"
  }, {
    path: "/ar/investing/products/equity",
    component: _558b5530,
    name: "investing-products-equity___ar"
  }, {
    path: "/ar/investing/products/etfs-funds",
    component: _610d57c0,
    name: "investing-products-etfs-funds___ar"
  }, {
    path: "/ar/investing/products/indices",
    component: _630794d8,
    name: "investing-products-indices___ar"
  }, {
    path: "/ar/investing/products/oil-futures",
    component: _16924afc,
    name: "investing-products-oil-futures___ar"
  }, {
    path: "/ar/investing/products/reits",
    component: _cba7247c,
    name: "investing-products-reits___ar"
  }, {
    path: "/ar/investing/products/single-stock-futures",
    component: _158fce77,
    name: "investing-products-single-stock-futures___ar"
  }, {
    path: "/ar/investing/products/sukuk-bonds",
    component: _9f2f0162,
    name: "investing-products-sukuk-bonds___ar"
  }, {
    path: "/ar/investing/products/union-coop",
    component: _16db09ae,
    name: "investing-products-union-coop___ar"
  }, {
    path: "/ar/investing/services/dfm-pjsc-unclaimed-dividends",
    component: _b69dfcfe,
    name: "investing-services-dfm-pjsc-unclaimed-dividends___ar"
  }, {
    path: "/ar/investing/services/dividend-distribution",
    component: _40f4946e,
    name: "investing-services-dividend-distribution___ar"
  }, {
    path: "/ar/investing/services/how-to-trade",
    component: _6691a618,
    name: "investing-services-how-to-trade___ar"
  }, {
    path: "/ar/investing/services/investor-information-update",
    component: _ac943f74,
    name: "investing-services-investor-information-update___ar"
  }, {
    path: "/ar/investing/services/ipo-subscriptions",
    component: _9ae89e8a,
    name: "investing-services-ipo-subscriptions___ar"
  }, {
    path: "/ar/investing/services/ivestor",
    component: _1834d443,
    name: "investing-services-ivestor___ar",
    children: [{
      path: "apple",
      component: _69a3b30e,
      name: "investing-services-ivestor-apple___ar"
    }, {
      path: "notification-form",
      component: _2c10001a,
      name: "investing-services-ivestor-notification-form___ar"
    }]
  }, {
    path: "/ar/investing/services/ivestor-apple-pay",
    component: _3164f4ab,
    name: "investing-services-ivestor-apple-pay___ar"
  }, {
    path: "/ar/investing/services/mobile-app",
    component: _184144df,
    name: "investing-services-mobile-app___ar",
    children: [{
      path: "reg",
      component: _537354e4,
      name: "investing-services-mobile-app-reg___ar"
    }, {
      path: "updateinfo",
      component: _3600fd57,
      name: "investing-services-mobile-app-updateinfo___ar"
    }]
  }, {
    path: "/ar/investing/services/pjsc-zakat",
    component: _37a0aafd,
    name: "investing-services-pjsc-zakat___ar"
  }, {
    path: "/ar/investing/services/rights-issue-subscription",
    component: _2a2cdd24,
    name: "investing-services-rights-issue-subscription___ar"
  }, {
    path: "/ar/issuers/listed-securities/company-profile-page",
    component: _38a3ecef,
    name: "issuers-listed-securities-company-profile-page___ar"
  }, {
    path: "/ar/raise-capital/ipo-listings/dual-listings",
    component: _2ccac863,
    name: "raise-capital-ipo-listings-dual-listings___ar"
  }, {
    path: "/ar/raise-capital/ipo-listings/established-companies",
    component: _360507a9,
    name: "raise-capital-ipo-listings-established-companies___ar"
  }, {
    path: "/ar/raise-capital/ipo-listings/family-businesses",
    component: _fd6d1d8c,
    name: "raise-capital-ipo-listings-family-businesses___ar"
  }, {
    path: "/ar/raise-capital/ipo-listings/free-zones",
    component: _728739f3,
    name: "raise-capital-ipo-listings-free-zones___ar"
  }, {
    path: "/ar/raise-capital/ipo-listings/overview",
    component: _5f9d3386,
    name: "raise-capital-ipo-listings-overview___ar"
  }, {
    path: "/ar/raise-capital/ipo-listings/private-companies",
    component: _1a628fd4,
    name: "raise-capital-ipo-listings-private-companies___ar"
  }, {
    path: "/ar/raise-capital/ipo-listings/sme",
    component: _93101564,
    name: "raise-capital-ipo-listings-sme___ar"
  }, {
    path: "/ar/raise-capital/listing-options/listing-equities",
    component: _5029a4e4,
    name: "raise-capital-listing-options-listing-equities___ar"
  }, {
    path: "/ar/raise-capital/listing-options/listing-etfs-funds",
    component: _69a6c67e,
    name: "raise-capital-listing-options-listing-etfs-funds___ar"
  }, {
    path: "/ar/raise-capital/listing-options/listing-reits",
    component: _6fc70eb8,
    name: "raise-capital-listing-options-listing-reits___ar"
  }, {
    path: "/ar/raise-capital/listing-options/listing-sukuk-bonds",
    component: _5be958b1,
    name: "raise-capital-listing-options-listing-sukuk-bonds___ar"
  }, {
    path: "/ar/raise-capital/listing-services/arena-platform",
    component: _a0c0905a,
    name: "raise-capital-listing-services-arena-platform___ar"
  }, {
    path: "/ar/raise-capital/listing-services/international-roadshows",
    component: _4f1bdce6,
    name: "raise-capital-listing-services-international-roadshows___ar"
  }, {
    path: "/ar/raise-capital/listing-services/ipo-accelerator-programme",
    component: _5a38b02a,
    name: "raise-capital-listing-services-ipo-accelerator-programme___ar"
  }, {
    path: "/ar/raise-capital/listing-services/registry-service",
    component: _c45d3402,
    name: "raise-capital-listing-services-registry-service___ar"
  }, {
    path: "/ar/the-exchange/market-information/direct-deals",
    component: _3cb2729d,
    name: "the-exchange-market-information-direct-deals___ar"
  }, {
    path: "/ar/the-exchange/market-information/indices",
    component: _0815b29d,
    children: [{
      path: "",
      component: _7e870f60,
      name: "the-exchange-market-information-indices___ar"
    }, {
      path: ":currentIndex",
      component: _78889646,
      name: "the-exchange-market-information-indices-currentIndex___ar"
    }]
  }, {
    path: "/ar/the-exchange/market-information/listed-securities",
    component: _30e87be4,
    name: "the-exchange-market-information-listed-securities___ar",
    children: [{
      path: "bonds",
      component: _1b3eada5,
      name: "the-exchange-market-information-listed-securities-bonds___ar"
    }, {
      path: "equities",
      component: _5a3a4dc4,
      name: "the-exchange-market-information-listed-securities-equities___ar"
    }, {
      path: "exchange-traded-funds",
      component: _1b4cbd40,
      name: "the-exchange-market-information-listed-securities-exchange-traded-funds___ar"
    }, {
      path: "funds",
      component: _48d1123a,
      name: "the-exchange-market-information-listed-securities-funds___ar"
    }, {
      path: "real-estate-investment-trust",
      component: _9a4cc5ea,
      name: "the-exchange-market-information-listed-securities-real-estate-investment-trust___ar"
    }, {
      path: "sukuk",
      component: _3e1fe298,
      name: "the-exchange-market-information-listed-securities-sukuk___ar"
    }]
  }, {
    path: "/ar/the-exchange/market-information/marketdata-providers",
    component: _11444f75,
    name: "the-exchange-market-information-marketdata-providers___ar"
  }, {
    path: "/ar/the-exchange/news-disclosures/corporate-actions",
    component: _689986dc,
    name: "the-exchange-news-disclosures-corporate-actions___ar"
  }, {
    path: "/ar/the-exchange/news-disclosures/derivatives-market-announcements",
    component: _6e3fbfe8,
    name: "the-exchange-news-disclosures-derivatives-market-announcements___ar"
  }, {
    path: "/ar/the-exchange/news-disclosures/disclosures",
    component: _6e69eaf0,
    name: "the-exchange-news-disclosures-disclosures___ar"
  }, {
    path: "/ar/the-exchange/news-disclosures/e-board",
    component: _3d9e2d05,
    name: "the-exchange-news-disclosures-e-board___ar",
    children: [{
      path: "positions",
      component: _5b961460,
      name: "the-exchange-news-disclosures-e-board-positions___ar"
    }, {
      path: "track-nominations",
      component: _72f6eb43,
      name: "the-exchange-news-disclosures-e-board-track-nominations___ar"
    }]
  }, {
    path: "/ar/the-exchange/news-disclosures/market-announcements",
    component: _38b65f47,
    name: "the-exchange-news-disclosures-market-announcements___ar"
  }, {
    path: "/ar/the-exchange/news-disclosures/settlement-trading-calender",
    component: _42dc1911,
    name: "the-exchange-news-disclosures-settlement-trading-calender___ar"
  }, {
    path: "/ar/the-exchange/regulation/circulars",
    component: _472624bc,
    name: "the-exchange-regulation-circulars___ar"
  }, {
    path: "/ar/the-exchange/regulation/market-rules",
    component: _3bf11c1e,
    name: "the-exchange-regulation-market-rules___ar"
  }, {
    path: "/ar/the-exchange/regulation/sharia-compliance",
    component: _8d2b80a2,
    name: "the-exchange-regulation-sharia-compliance___ar"
  }, {
    path: "/ar/the-exchange/statistics-reports/bulletins",
    component: _d2f565c8,
    name: "the-exchange-statistics-reports-bulletins___ar"
  }, {
    path: "/ar/the-exchange/statistics-reports/classification-list-page",
    component: _bc25d712,
    name: "the-exchange-statistics-reports-classification-list-page___ar"
  }, {
    path: "/ar/the-exchange/statistics-reports/foreign-ownership",
    component: _6fac8a26,
    name: "the-exchange-statistics-reports-foreign-ownership___ar"
  }, {
    path: "/ar/the-exchange/statistics-reports/historical-data",
    component: _ab4b8c86,
    name: "the-exchange-statistics-reports-historical-data___ar",
    children: [{
      path: "company-prices",
      component: _532b01dc,
      name: "the-exchange-statistics-reports-historical-data-company-prices___ar"
    }, {
      path: "dfmgi",
      component: _1e20927b,
      name: "the-exchange-statistics-reports-historical-data-dfmgi___ar"
    }, {
      path: "futures",
      component: _ef967784,
      name: "the-exchange-statistics-reports-historical-data-futures___ar"
    }, {
      path: "sector-indices",
      component: _8bcb20fc,
      name: "the-exchange-statistics-reports-historical-data-sector-indices___ar"
    }, {
      path: "trade-by-client-type",
      component: _5b16f15e,
      name: "the-exchange-statistics-reports-historical-data-trade-by-client-type___ar"
    }, {
      path: "trade-by-nationality",
      component: _6755fdde,
      name: "the-exchange-statistics-reports-historical-data-trade-by-nationality___ar"
    }]
  }, {
    path: "/ar/the-exchange/statistics-reports/ownership-holding-statistics",
    component: _350d66cb,
    name: "the-exchange-statistics-reports-ownership-holding-statistics___ar"
  }, {
    path: "/ar/the-exchange/statistics-reports/sharia-classification-list",
    component: _bbca8714,
    name: "the-exchange-statistics-reports-sharia-classification-list___ar"
  }, {
    path: "/ar/the-exchange/statistics-reports/weekly-margin-trading-report",
    component: _96fd035a,
    name: "the-exchange-statistics-reports-weekly-margin-trading-report___ar"
  }, {
    path: "/en/discover-dfm/investor-relations/key-figures",
    component: _8240f394,
    name: "discover-dfm-investor-relations-key-figures___en"
  }, {
    path: "/en/discover-dfm/investor-relations/reports-presentations",
    component: _2ae97160,
    name: "discover-dfm-investor-relations-reports-presentations___en"
  }, {
    path: "/en/investing/products/carbon-credits",
    component: _8aa1dd46,
    name: "investing-products-carbon-credits___en",
    children: [{
      path: "detail",
      component: _61507573,
      name: "investing-products-carbon-credits-detail___en"
    }, {
      path: "hello",
      component: _6f7763c0,
      name: "investing-products-carbon-credits-hello___en"
    }, {
      path: "join-the-pilot-form",
      component: _76106866,
      name: "investing-products-carbon-credits-join-the-pilot-form___en"
    }, {
      path: ":id?",
      component: _529d6888,
      name: "investing-products-carbon-credits-id___en"
    }, {
      path: ":symbol?",
      component: _d369cff6,
      name: "investing-products-carbon-credits-symbol___en"
    }]
  }, {
    path: "/en/investing/products/derivatives",
    component: _232034b9,
    name: "investing-products-derivatives___en"
  }, {
    path: "/en/investing/products/equity",
    component: _558b5530,
    name: "investing-products-equity___en"
  }, {
    path: "/en/investing/products/etfs-funds",
    component: _610d57c0,
    name: "investing-products-etfs-funds___en"
  }, {
    path: "/en/investing/products/indices",
    component: _630794d8,
    name: "investing-products-indices___en"
  }, {
    path: "/en/investing/products/oil-futures",
    component: _16924afc,
    name: "investing-products-oil-futures___en"
  }, {
    path: "/en/investing/products/reits",
    component: _cba7247c,
    name: "investing-products-reits___en"
  }, {
    path: "/en/investing/products/single-stock-futures",
    component: _158fce77,
    name: "investing-products-single-stock-futures___en"
  }, {
    path: "/en/investing/products/sukuk-bonds",
    component: _9f2f0162,
    name: "investing-products-sukuk-bonds___en"
  }, {
    path: "/en/investing/products/union-coop",
    component: _16db09ae,
    name: "investing-products-union-coop___en"
  }, {
    path: "/en/investing/services/dfm-pjsc-unclaimed-dividends",
    component: _b69dfcfe,
    name: "investing-services-dfm-pjsc-unclaimed-dividends___en"
  }, {
    path: "/en/investing/services/dividend-distribution",
    component: _40f4946e,
    name: "investing-services-dividend-distribution___en"
  }, {
    path: "/en/investing/services/how-to-trade",
    component: _6691a618,
    name: "investing-services-how-to-trade___en"
  }, {
    path: "/en/investing/services/investor-information-update",
    component: _ac943f74,
    name: "investing-services-investor-information-update___en"
  }, {
    path: "/en/investing/services/ipo-subscriptions",
    component: _9ae89e8a,
    name: "investing-services-ipo-subscriptions___en"
  }, {
    path: "/en/investing/services/ivestor",
    component: _1834d443,
    name: "investing-services-ivestor___en",
    children: [{
      path: "apple",
      component: _69a3b30e,
      name: "investing-services-ivestor-apple___en"
    }, {
      path: "notification-form",
      component: _2c10001a,
      name: "investing-services-ivestor-notification-form___en"
    }]
  }, {
    path: "/en/investing/services/ivestor-apple-pay",
    component: _3164f4ab,
    name: "investing-services-ivestor-apple-pay___en"
  }, {
    path: "/en/investing/services/mobile-app",
    component: _184144df,
    name: "investing-services-mobile-app___en",
    children: [{
      path: "reg",
      component: _537354e4,
      name: "investing-services-mobile-app-reg___en"
    }, {
      path: "updateinfo",
      component: _3600fd57,
      name: "investing-services-mobile-app-updateinfo___en"
    }]
  }, {
    path: "/en/investing/services/pjsc-zakat",
    component: _37a0aafd,
    name: "investing-services-pjsc-zakat___en"
  }, {
    path: "/en/investing/services/rights-issue-subscription",
    component: _2a2cdd24,
    name: "investing-services-rights-issue-subscription___en"
  }, {
    path: "/en/issuers/listed-securities/company-profile-page",
    component: _38a3ecef,
    name: "issuers-listed-securities-company-profile-page___en"
  }, {
    path: "/en/raise-capital/ipo-listings/dual-listings",
    component: _2ccac863,
    name: "raise-capital-ipo-listings-dual-listings___en"
  }, {
    path: "/en/raise-capital/ipo-listings/established-companies",
    component: _360507a9,
    name: "raise-capital-ipo-listings-established-companies___en"
  }, {
    path: "/en/raise-capital/ipo-listings/family-businesses",
    component: _fd6d1d8c,
    name: "raise-capital-ipo-listings-family-businesses___en"
  }, {
    path: "/en/raise-capital/ipo-listings/free-zones",
    component: _728739f3,
    name: "raise-capital-ipo-listings-free-zones___en"
  }, {
    path: "/en/raise-capital/ipo-listings/overview",
    component: _5f9d3386,
    name: "raise-capital-ipo-listings-overview___en"
  }, {
    path: "/en/raise-capital/ipo-listings/private-companies",
    component: _1a628fd4,
    name: "raise-capital-ipo-listings-private-companies___en"
  }, {
    path: "/en/raise-capital/ipo-listings/sme",
    component: _93101564,
    name: "raise-capital-ipo-listings-sme___en"
  }, {
    path: "/en/raise-capital/listing-options/listing-equities",
    component: _5029a4e4,
    name: "raise-capital-listing-options-listing-equities___en"
  }, {
    path: "/en/raise-capital/listing-options/listing-etfs-funds",
    component: _69a6c67e,
    name: "raise-capital-listing-options-listing-etfs-funds___en"
  }, {
    path: "/en/raise-capital/listing-options/listing-reits",
    component: _6fc70eb8,
    name: "raise-capital-listing-options-listing-reits___en"
  }, {
    path: "/en/raise-capital/listing-options/listing-sukuk-bonds",
    component: _5be958b1,
    name: "raise-capital-listing-options-listing-sukuk-bonds___en"
  }, {
    path: "/en/raise-capital/listing-services/arena-platform",
    component: _a0c0905a,
    name: "raise-capital-listing-services-arena-platform___en"
  }, {
    path: "/en/raise-capital/listing-services/international-roadshows",
    component: _4f1bdce6,
    name: "raise-capital-listing-services-international-roadshows___en"
  }, {
    path: "/en/raise-capital/listing-services/ipo-accelerator-programme",
    component: _5a38b02a,
    name: "raise-capital-listing-services-ipo-accelerator-programme___en"
  }, {
    path: "/en/raise-capital/listing-services/registry-service",
    component: _c45d3402,
    name: "raise-capital-listing-services-registry-service___en"
  }, {
    path: "/en/the-exchange/market-information/direct-deals",
    component: _3cb2729d,
    name: "the-exchange-market-information-direct-deals___en"
  }, {
    path: "/en/the-exchange/market-information/indices",
    component: _0815b29d,
    children: [{
      path: "",
      component: _7e870f60,
      name: "the-exchange-market-information-indices___en"
    }, {
      path: ":currentIndex",
      component: _78889646,
      name: "the-exchange-market-information-indices-currentIndex___en"
    }]
  }, {
    path: "/en/the-exchange/market-information/listed-securities",
    component: _30e87be4,
    name: "the-exchange-market-information-listed-securities___en",
    children: [{
      path: "bonds",
      component: _1b3eada5,
      name: "the-exchange-market-information-listed-securities-bonds___en"
    }, {
      path: "equities",
      component: _5a3a4dc4,
      name: "the-exchange-market-information-listed-securities-equities___en"
    }, {
      path: "exchange-traded-funds",
      component: _1b4cbd40,
      name: "the-exchange-market-information-listed-securities-exchange-traded-funds___en"
    }, {
      path: "funds",
      component: _48d1123a,
      name: "the-exchange-market-information-listed-securities-funds___en"
    }, {
      path: "real-estate-investment-trust",
      component: _9a4cc5ea,
      name: "the-exchange-market-information-listed-securities-real-estate-investment-trust___en"
    }, {
      path: "sukuk",
      component: _3e1fe298,
      name: "the-exchange-market-information-listed-securities-sukuk___en"
    }]
  }, {
    path: "/en/the-exchange/market-information/marketdata-providers",
    component: _11444f75,
    name: "the-exchange-market-information-marketdata-providers___en"
  }, {
    path: "/en/the-exchange/news-disclosures/corporate-actions",
    component: _689986dc,
    name: "the-exchange-news-disclosures-corporate-actions___en"
  }, {
    path: "/en/the-exchange/news-disclosures/derivatives-market-announcements",
    component: _6e3fbfe8,
    name: "the-exchange-news-disclosures-derivatives-market-announcements___en"
  }, {
    path: "/en/the-exchange/news-disclosures/disclosures",
    component: _6e69eaf0,
    name: "the-exchange-news-disclosures-disclosures___en"
  }, {
    path: "/en/the-exchange/news-disclosures/e-board",
    component: _3d9e2d05,
    name: "the-exchange-news-disclosures-e-board___en",
    children: [{
      path: "positions",
      component: _5b961460,
      name: "the-exchange-news-disclosures-e-board-positions___en"
    }, {
      path: "track-nominations",
      component: _72f6eb43,
      name: "the-exchange-news-disclosures-e-board-track-nominations___en"
    }]
  }, {
    path: "/en/the-exchange/news-disclosures/market-announcements",
    component: _38b65f47,
    name: "the-exchange-news-disclosures-market-announcements___en"
  }, {
    path: "/en/the-exchange/news-disclosures/settlement-trading-calender",
    component: _42dc1911,
    name: "the-exchange-news-disclosures-settlement-trading-calender___en"
  }, {
    path: "/en/the-exchange/regulation/circulars",
    component: _472624bc,
    name: "the-exchange-regulation-circulars___en"
  }, {
    path: "/en/the-exchange/regulation/market-rules",
    component: _3bf11c1e,
    name: "the-exchange-regulation-market-rules___en"
  }, {
    path: "/en/the-exchange/regulation/sharia-compliance",
    component: _8d2b80a2,
    name: "the-exchange-regulation-sharia-compliance___en"
  }, {
    path: "/en/the-exchange/statistics-reports/bulletins",
    component: _d2f565c8,
    name: "the-exchange-statistics-reports-bulletins___en"
  }, {
    path: "/en/the-exchange/statistics-reports/classification-list-page",
    component: _bc25d712,
    name: "the-exchange-statistics-reports-classification-list-page___en"
  }, {
    path: "/en/the-exchange/statistics-reports/foreign-ownership",
    component: _6fac8a26,
    name: "the-exchange-statistics-reports-foreign-ownership___en"
  }, {
    path: "/en/the-exchange/statistics-reports/historical-data",
    component: _ab4b8c86,
    name: "the-exchange-statistics-reports-historical-data___en",
    children: [{
      path: "company-prices",
      component: _532b01dc,
      name: "the-exchange-statistics-reports-historical-data-company-prices___en"
    }, {
      path: "dfmgi",
      component: _1e20927b,
      name: "the-exchange-statistics-reports-historical-data-dfmgi___en"
    }, {
      path: "futures",
      component: _ef967784,
      name: "the-exchange-statistics-reports-historical-data-futures___en"
    }, {
      path: "sector-indices",
      component: _8bcb20fc,
      name: "the-exchange-statistics-reports-historical-data-sector-indices___en"
    }, {
      path: "trade-by-client-type",
      component: _5b16f15e,
      name: "the-exchange-statistics-reports-historical-data-trade-by-client-type___en"
    }, {
      path: "trade-by-nationality",
      component: _6755fdde,
      name: "the-exchange-statistics-reports-historical-data-trade-by-nationality___en"
    }]
  }, {
    path: "/en/the-exchange/statistics-reports/ownership-holding-statistics",
    component: _350d66cb,
    name: "the-exchange-statistics-reports-ownership-holding-statistics___en"
  }, {
    path: "/en/the-exchange/statistics-reports/sharia-classification-list",
    component: _bbca8714,
    name: "the-exchange-statistics-reports-sharia-classification-list___en"
  }, {
    path: "/en/the-exchange/statistics-reports/weekly-margin-trading-report",
    component: _96fd035a,
    name: "the-exchange-statistics-reports-weekly-margin-trading-report___en"
  }, {
    path: "/issuers/listed-securities/securities/company-profile-page",
    component: _7ec24e50,
    name: "issuers-listed-securities-securities-company-profile-page___en___default"
  }, {
    path: "/ar/issuers/listed-securities/securities/company-profile-page",
    component: _7ec24e50,
    name: "issuers-listed-securities-securities-company-profile-page___ar"
  }, {
    path: "/en/issuers/listed-securities/securities/company-profile-page",
    component: _7ec24e50,
    name: "issuers-listed-securities-securities-company-profile-page___en"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___en___default"
  }, {
    path: "/ar/investing/services/ipo-subscriptions/:IPOCode?",
    component: _95150706,
    name: "investing-services-ipo-subscriptions-IPOCode___ar"
  }, {
    path: "/ar/the-exchange/market-information/company/:SecuritySymbol?",
    component: _3ae184ca,
    name: "the-exchange-market-information-company-SecuritySymbol___ar",
    children: [{
      path: "corporate-actions",
      component: _5ba81321,
      name: "the-exchange-market-information-company-SecuritySymbol-corporate-actions___ar"
    }, {
      path: "general-meetings",
      component: _27258701,
      name: "the-exchange-market-information-company-SecuritySymbol-general-meetings___ar"
    }, {
      path: "news-disclosures",
      component: _0d2116d0,
      name: "the-exchange-market-information-company-SecuritySymbol-news-disclosures___ar"
    }, {
      path: "profile",
      component: _43ae7435,
      name: "the-exchange-market-information-company-SecuritySymbol-profile___ar"
    }, {
      path: "reports",
      component: _2b70414b,
      name: "the-exchange-market-information-company-SecuritySymbol-reports___ar"
    }, {
      path: "trading",
      component: _a17298a6,
      name: "the-exchange-market-information-company-SecuritySymbol-trading___ar",
      children: [{
        path: "daily-summary",
        component: _cae1b4e0,
        name: "the-exchange-market-information-company-SecuritySymbol-trading-daily-summary___ar"
      }, {
        path: "foreign-investments",
        component: _4a15d9b6,
        name: "the-exchange-market-information-company-SecuritySymbol-trading-foreign-investments___ar"
      }, {
        path: "nav-summary",
        component: _1982fb1a,
        name: "the-exchange-market-information-company-SecuritySymbol-trading-nav-summary___ar"
      }, {
        path: "top-shareholders",
        component: _77b8945c,
        name: "the-exchange-market-information-company-SecuritySymbol-trading-top-shareholders___ar"
      }, {
        path: "trading-summary",
        component: _77485718,
        name: "the-exchange-market-information-company-SecuritySymbol-trading-trading-summary___ar"
      }]
    }]
  }, {
    path: "/ar/the-exchange/news-disclosures/derivatives-market-announcements/:id?",
    component: _61e6c760,
    name: "the-exchange-news-disclosures-derivatives-market-announcements-id___ar"
  }, {
    path: "/ar/the-exchange/news-disclosures/disclosures/:id?",
    component: _07542d50,
    name: "the-exchange-news-disclosures-disclosures-id___ar"
  }, {
    path: "/ar/the-exchange/news-disclosures/market-announcements/:id?",
    component: _f1dcf122,
    name: "the-exchange-news-disclosures-market-announcements-id___ar"
  }, {
    path: "/ar/the-exchange/regulation/circulars/:id?",
    component: _2ba3b3b8,
    name: "the-exchange-regulation-circulars-id___ar"
  }, {
    path: "/en/investing/services/ipo-subscriptions/:IPOCode?",
    component: _95150706,
    name: "investing-services-ipo-subscriptions-IPOCode___en"
  }, {
    path: "/en/the-exchange/market-information/company/:SecuritySymbol?",
    component: _3ae184ca,
    name: "the-exchange-market-information-company-SecuritySymbol___en",
    children: [{
      path: "corporate-actions",
      component: _5ba81321,
      name: "the-exchange-market-information-company-SecuritySymbol-corporate-actions___en"
    }, {
      path: "general-meetings",
      component: _27258701,
      name: "the-exchange-market-information-company-SecuritySymbol-general-meetings___en"
    }, {
      path: "news-disclosures",
      component: _0d2116d0,
      name: "the-exchange-market-information-company-SecuritySymbol-news-disclosures___en"
    }, {
      path: "profile",
      component: _43ae7435,
      name: "the-exchange-market-information-company-SecuritySymbol-profile___en"
    }, {
      path: "reports",
      component: _2b70414b,
      name: "the-exchange-market-information-company-SecuritySymbol-reports___en"
    }, {
      path: "trading",
      component: _a17298a6,
      name: "the-exchange-market-information-company-SecuritySymbol-trading___en",
      children: [{
        path: "daily-summary",
        component: _cae1b4e0,
        name: "the-exchange-market-information-company-SecuritySymbol-trading-daily-summary___en"
      }, {
        path: "foreign-investments",
        component: _4a15d9b6,
        name: "the-exchange-market-information-company-SecuritySymbol-trading-foreign-investments___en"
      }, {
        path: "nav-summary",
        component: _1982fb1a,
        name: "the-exchange-market-information-company-SecuritySymbol-trading-nav-summary___en"
      }, {
        path: "top-shareholders",
        component: _77b8945c,
        name: "the-exchange-market-information-company-SecuritySymbol-trading-top-shareholders___en"
      }, {
        path: "trading-summary",
        component: _77485718,
        name: "the-exchange-market-information-company-SecuritySymbol-trading-trading-summary___en"
      }]
    }]
  }, {
    path: "/en/the-exchange/news-disclosures/derivatives-market-announcements/:id?",
    component: _61e6c760,
    name: "the-exchange-news-disclosures-derivatives-market-announcements-id___en"
  }, {
    path: "/en/the-exchange/news-disclosures/disclosures/:id?",
    component: _07542d50,
    name: "the-exchange-news-disclosures-disclosures-id___en"
  }, {
    path: "/en/the-exchange/news-disclosures/market-announcements/:id?",
    component: _f1dcf122,
    name: "the-exchange-news-disclosures-market-announcements-id___en"
  }, {
    path: "/en/the-exchange/regulation/circulars/:id?",
    component: _2ba3b3b8,
    name: "the-exchange-regulation-circulars-id___en"
  }, {
    path: "/ar/members/member-services/:Id?",
    component: _63a38060,
    name: "members-member-services-Id___ar"
  }, {
    path: "/en/members/member-services/:Id?",
    component: _63a38060,
    name: "members-member-services-Id___en"
  }, {
    path: "/investing/services/ipo-subscriptions/:IPOCode?",
    component: _95150706,
    name: "investing-services-ipo-subscriptions-IPOCode___en___default"
  }, {
    path: "/the-exchange/market-information/company/:SecuritySymbol?",
    component: _3ae184ca,
    name: "the-exchange-market-information-company-SecuritySymbol___en___default",
    children: [{
      path: "corporate-actions",
      component: _5ba81321,
      name: "the-exchange-market-information-company-SecuritySymbol-corporate-actions___en___default"
    }, {
      path: "general-meetings",
      component: _27258701,
      name: "the-exchange-market-information-company-SecuritySymbol-general-meetings___en___default"
    }, {
      path: "news-disclosures",
      component: _0d2116d0,
      name: "the-exchange-market-information-company-SecuritySymbol-news-disclosures___en___default"
    }, {
      path: "profile",
      component: _43ae7435,
      name: "the-exchange-market-information-company-SecuritySymbol-profile___en___default"
    }, {
      path: "reports",
      component: _2b70414b,
      name: "the-exchange-market-information-company-SecuritySymbol-reports___en___default"
    }, {
      path: "trading",
      component: _a17298a6,
      name: "the-exchange-market-information-company-SecuritySymbol-trading___en___default",
      children: [{
        path: "daily-summary",
        component: _cae1b4e0,
        name: "the-exchange-market-information-company-SecuritySymbol-trading-daily-summary___en___default"
      }, {
        path: "foreign-investments",
        component: _4a15d9b6,
        name: "the-exchange-market-information-company-SecuritySymbol-trading-foreign-investments___en___default"
      }, {
        path: "nav-summary",
        component: _1982fb1a,
        name: "the-exchange-market-information-company-SecuritySymbol-trading-nav-summary___en___default"
      }, {
        path: "top-shareholders",
        component: _77b8945c,
        name: "the-exchange-market-information-company-SecuritySymbol-trading-top-shareholders___en___default"
      }, {
        path: "trading-summary",
        component: _77485718,
        name: "the-exchange-market-information-company-SecuritySymbol-trading-trading-summary___en___default"
      }]
    }]
  }, {
    path: "/the-exchange/news-disclosures/derivatives-market-announcements/:id?",
    component: _61e6c760,
    name: "the-exchange-news-disclosures-derivatives-market-announcements-id___en___default"
  }, {
    path: "/the-exchange/news-disclosures/disclosures/:id?",
    component: _07542d50,
    name: "the-exchange-news-disclosures-disclosures-id___en___default"
  }, {
    path: "/the-exchange/news-disclosures/market-announcements/:id?",
    component: _f1dcf122,
    name: "the-exchange-news-disclosures-market-announcements-id___en___default"
  }, {
    path: "/the-exchange/regulation/circulars/:id?",
    component: _2ba3b3b8,
    name: "the-exchange-regulation-circulars-id___en___default"
  }, {
    path: "/members/member-services/:Id?",
    component: _63a38060,
    name: "members-member-services-Id___en___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
