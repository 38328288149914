export const state = () => ({
  latestPress: [],
})

//Mutations - Populating the state with data
export const mutations = {
  SET_LATESTPRESS(state, payload) {
    state.latestPress = payload
  },
}
export const getters = {
  getLatestPress: (state) => {
    return state.latestPress
    // return arg;
  },
}
export const actions = {
  async getLatestPress({ commit }) {
    const latestDisclosures = await this.$axios.get(`${this.$config.apiEfsahUrl}/prototype_efsah`, {
      params: {
        lang: this.$i18n.locale,
        h7_datetime_format: 'MMM dd, yyyy HH:mm:ss',
        labels: 'pr',
        symbol: 'DFM',
        cms_resources: true,
        take: 3,
      },
    })

    let data = latestDisclosures.data.root
    commit('SET_LATESTPRESS', data)
  },
}
