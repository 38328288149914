import Vue from 'vue'
import numeral from 'numeral'
import numFormat from 'vue-filter-number-format'
import dayjs from 'dayjs'

Vue.filter('numFormat', numFormat(numeral))

Vue.filter('numberFormat', function (val, format) {
  if (!val || val == '--') return '--'
  if (!format) {
    format = '0,0.000'
  }
  return numeral(val).format(format)
})

Vue.filter('priceDecimal', function (val, decimal) {
  if (val == null || val == undefined || val == '--') return '--'
  let format
  if (decimal == 0) {
    format = '0,0.000'
  }
  if (decimal == 2) {
    format = '0,0.00'
  } else if (decimal == 3) {
    format = '0,0.000'
  }
  return numeral(val).format(format)
})

// In your main.js you can register a custom filter
// https://vuejs.org/v2/guide/filters.html

Vue.filter('nullNumber', function (val) {
  if (!val || val == 0 || val == null || val == undefined) return '--'
  else {
    return val
  }
})
Vue.filter('nullData', function (val) {
  if (!val || val == 0 || val == '' || val == null) return '--'
  else {
    return val
  }
})
Vue.filter('nullCurrency', function (val) {
  if (!val) return '0.00'
  else {
    return val
  }
})

Vue.filter('prettyDate', function (val, format) {
  if (!format) {
    format = 'DD MMM, YYYY'
  }
  val ? (val = dayjs(val).format(format)) : (val = '--')
  return val
})

Vue.filter('addhttp', function (url) {
  if (!url) return ''
  if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
    url = 'https://' + url
  }
  return url
})
Vue.filter('convertDomain', function (url) {
  if (typeof url !== 'string') {
    return url
  }

  if (url.startsWith('http://')) {
    return url.replace('http://', 'https://')
  }

  return url
})
