import Feeds from '~/api/feeds'

export default (context, inject) => {
    // Initialize API factories
    const factories = {
        feeds: Feeds(context.$axios),
    }

    // Inject $api
    inject('api', factories)
}
