import dayjs from 'dayjs'

import 'dayjs/locale/en'
import 'dayjs/locale/ar'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import weekday from 'dayjs/plugin/weekday'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(isBetween)
dayjs.extend(weekday)
dayjs.extend(customParseFormat)

dayjs.locale('en')

dayjs.tz.setDefault('Asia/Dubai')

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}
