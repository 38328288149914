import Vue from 'vue'
Vue.prototype.$appleStoreLink = 'https://apps.apple.com/ae/app/dfm-dubai-financial-market/id997641752'
Vue.prototype.$iVestorAppleStoreLink = 'https://apps.apple.com/us/app/ivestor/id1628119841'
Vue.prototype.$googleStoreLink = 'https://play.google.com/store/apps/details?id=com.DFM'
Vue.prototype.$iVestorGoogleStoreLink = 'https://play.google.com/store/apps/details?id=ae.dfm.ivestorapp&pcampaignid=web_share'

Vue.prototype.$servicesArrEn = ['Equity Trading', 'Derivative Trading', 'Margin Trading', 'Short-Term Margin Trading', 'Direct Market Access - DMA', 'Internet Trading', 'Mobile Trading', 'Authorized Participants - AP', 'Liquidity Provider - LP', 'Regulated Short Selling', 'Account Allocation', 'Trading of the Brokerage Company in its own name and for its benefit', 'Digital onboarding through DFM App', 'Digital onboarding through broker’s platform', 'Price Stabilization']

Vue.prototype.$servicesArrAr = ['تداول الأوراق المالية', 'تداول العقود المستقبلية', 'تداول الهامش', 'تداول بالهامش قصير المدى', 'التداول المباشر عبر الأسواق', 'التداول عبر الإنترنت', 'رخصة التداول عبر الهاتف المتحرك ', 'رخصة المفوضين المعتمدين', 'LP موفر السيولة', 'البيع على المكشوف المنظم', 'حساب التخصيص', 'تداول شركة الوساطة باسمها ولحسابها الخاص', 'خدمة فتح حساب تداول عبر تطبيق سوق دبي المالي', 'خدمة فتح حساب تداول عبر منصة الوسيط الإلكترونية', 'الاستقرار السعري']

Vue.prototype.$monthsEn = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
Vue.prototype.$monthsAr = ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر']
